import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import tr from 'date-fns/locale/tr';

registerLocale('tr', tr);
setDefaultLocale('tr');

const SalesList = () => {
  const [sales, setSales] = useState([]);
  const [filteredSales, setFilteredSales] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const salesPerPage = 5;

  useEffect(() => {
    const fetchSales = async () => {
      try {
        const response = await axios.get('https://zirvecrm.com/get_sales.php');
        setSales(response.data);
        setFilteredSales(response.data);
      } catch (error) {
        console.error('Satışlar yüklenirken bir hata oluştu', error);
      }
    };

    fetchSales();
  }, []);

  useEffect(() => {
    const filtered = sales.filter((sale) => {
      const matchesSearchTerm =
        sale.company_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        sale.policy_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
        sale.product_name.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesDateRange =
        (!startDate || new Date(sale.policy_start_date) >= startDate) &&
        (!endDate || new Date(sale.policy_end_date) <= endDate);
      return matchesSearchTerm && matchesDateRange;
    });
    setFilteredSales(filtered);
  }, [searchTerm, sales, startDate, endDate]);

  const formatCurrency = (value) => {
    const numberValue = parseFloat(value) / 100;
    return new Intl.NumberFormat('tr-TR', {
      style: 'currency',
      currency: 'TRY',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(numberValue).replace('₺', '').trim();
  };

  const formatCommission = (value) => {
    const numberValue = parseFloat(value);
    return new Intl.NumberFormat('tr-TR', {
      style: 'currency',
      currency: 'TRY',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(numberValue).replace('₺', '').trim();
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const offset = currentPage * salesPerPage;
  const currentSales = filteredSales.slice(offset, offset + salesPerPage);

  const productGroups = [
    'Trafik Sigortası',
    'Kasko',
    'Sağlık Sigortası',
    'Konut Sigortası',
    'Dask',
    'Ferdi Kaza & Yol Yardım',
    'İMM'  // Yeni eklenen ürün
  ];

  const summaryTables = productGroups.map((group) => {
    const groupSales = sales.filter((sale) => 
      sale.product_name.toLowerCase() === group.toLowerCase()
    );
    const totalAmount = groupSales.reduce((sum, sale) => sum + parseFloat(sale.gross_premium), 0);
    return (
      <div key={group} className="bg-white shadow-md rounded-lg p-4 m-4 w-1/3">
        <h3 className="text-lg font-semibold mb-2">{group}</h3>
        <p>Toplam Satış Tutarı: {formatCurrency(totalAmount)}</p>
        <p>Toplam Satış Adeti: {groupSales.length}</p>
      </div>
    );
  });

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Satışlarım</h2>

      <div className="mb-4 flex gap-4">
        <input
          type="text"
          placeholder="Arama yap..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          placeholderText="Başlangıç Tarihi"
          className="appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          locale="tr"
          dateFormat="dd.MM.yyyy"
        />
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          placeholderText="Bitiş Tarihi"
          className="appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          locale="tr"
          dateFormat="dd.MM.yyyy"
        />
      </div>

      <table className="min-w-full bg-white">
        <thead className="bg-gray-800 text-white">
          <tr>
            <th className="w-1/4 text-left py-3 px-4 uppercase font-semibold text-sm">Şirket Adı</th>
            <th className="w-1/6 text-left py-3 px-4 uppercase font-semibold text-sm">Poliçe Numarası</th>
            <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Ürün Adı</th>
            <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Net Prim</th>
            <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Brüt Prim</th>
            <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Başlangıç Tarihi</th>
            <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Bitiş Tarihi</th>
            <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Yeni/Yenileme</th>
            <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Prim</th>
          </tr>
        </thead>
        <tbody className="text-gray-700">
          {currentSales.map((sale) => (
            <tr key={sale.id}>
              <td className="w-1/4 text-left py-3 px-4">{sale.company_name}</td>
              <td className="w-1/6 text-left py-3 px-4">{sale.policy_number}</td>
              <td className="text-left py-3 px-4">{sale.product_name}</td>
              <td className="text-left py-3 px-4">{formatCurrency(sale.net_premium)}</td>
              <td className="text-left py-3 px-4">{formatCurrency(sale.gross_premium)}</td>
              <td className="text-left py-3 px-4">{new Date(sale.policy_start_date).toLocaleDateString('tr-TR')}</td>
              <td className="text-left py-3 px-4">{new Date(sale.policy_end_date).toLocaleDateString('tr-TR')}</td>
              <td className="text-left py-3 px-4">{sale.new_or_renewal}</td>
              <td className="text-left py-3 px-4">{formatCommission(sale.commission)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <ReactPaginate
        previousLabel={"Önceki"}
        nextLabel={"Sonraki"}
        pageCount={Math.ceil(filteredSales.length / salesPerPage)}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
        className="flex justify-center mt-4"
        pageClassName="mx-2"
      />

      <div className="mt-8">
        <h3 className="text-xl font-bold mb-4">Ürün Gruplarına Göre Satış Özeti</h3>
        <div className="flex flex-wrap -m-4">
          {summaryTables}
        </div>
      </div>
    </div>
  );
};

export default SalesList;