import React, { useState } from 'react';
import { Home, Target, TrendingUp, Award, FileText, User, Menu, LogOut } from 'lucide-react';
import Profile from './Profile';
import PDFReader from './PDFReader';
import Performance from './Performance';
import { Link, useNavigate, useLocation, Routes, Route } from 'react-router-dom';
import SalesList from './SalesList'; // SalesList bileşeninin dosya yolu burada verilmiştir


const TableCard = ({ title, children }) => (
  <div className="bg-white shadow-md rounded-lg p-4 mb-4">
    <h3 className="text-lg font-semibold mb-2">{title}</h3>
    {children}
  </div>
);

const Layout = ({ setIsAuthenticated }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => setMenuOpen(!menuOpen);



  const handleLogout = async () => {
    try {
      const response = await fetch('https://zirvecrm.com/logout.php', {
        method: 'POST',
        credentials: 'include'
      });
      if (response.ok) {
        setIsAuthenticated(false);
        navigate('/login');
      } else {
        console.error('Çıkış yapılırken bir hata oluştu');
      }
    } catch (error) {
      console.error('Çıkış yapılırken bir hata oluştu', error);
    }
  };

  const renderHomePage = () => (
    <>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-semibold text-gray-800">Hoş Geldiniz</h2>

      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <TableCard title="Duyurular">
          <ul className="list-disc pl-5">
            <li>Yeni ürün lansmanı: Cyber Sigorta</li>
            <li>Satış hedefleri güncellendi</li>
            <li>Eğitim semineri: 15 Eylül</li>
          </ul>
        </TableCard>
        
        <TableCard title="Şirket Hedefi">
          <div className="flex items-center">
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div className="bg-blue-600 h-2.5 rounded-full" style={{width: '70%'}}></div>
            </div>
            <span className="ml-2 text-sm font-medium text-gray-500">70%</span>
          </div>
          <p className="mt-2 text-sm text-gray-600">Hedef: 1,000,000 TL / Gerçekleşen: 700,000 TL</p>
        </TableCard>
        
        <TableCard title="Sıralamam">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="text-left">Sıra</th>
                <th className="text-left">İsim</th>
                <th className="text-left">Satış</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Ahmet Yılmaz</td>
                <td>120,000 TL</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Siz</td>
                <td>115,000 TL</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Mehmet Kaya</td>
                <td>110,000 TL</td>
              </tr>
            </tbody>
          </table>
        </TableCard>
        
        <TableCard title="Doğum Günleri">
          <ul className="list-disc pl-5">
            <li>Ali Veli - 5 Eylül</li>
            <li>Ayşe Fatma - 12 Eylül</li>
            <li>Mehmet Yılmaz - 20 Eylül</li>
          </ul>
        </TableCard>
      </div>
    </>
  );

  const menuItems = [
    { icon: <Home size={20} />, text: 'Anasayfa', path: '/' },
    { icon: <User size={20} />, text: 'PDF', path: '/pdf' },
    { icon: <TrendingUp size={20} />, text: 'Satışlar', path: '/satislar' }, // Yeni ekleme
    { icon: <Target size={20} />, text: 'Hedefler', path: '/hedefler' },
    { icon: <TrendingUp size={20} />, text: 'Performansım', path: '/performans' },
    { icon: <Award size={20} />, text: 'Başarılar', path: '/basarilar' },
    { icon: <FileText size={20} />, text: 'Talepler', path: '/talepler' },
    { icon: <User size={20} />, text: 'Profil', path: '/profil' },

    

  ];

  return (
    <div className="flex h-screen bg-gray-100 font-sans">
      {/* Sol Menü */}
      <aside className={`bg-blue-700 text-white w-64 min-h-screen p-4 ${menuOpen ? '' : 'hidden'} md:block transition-all duration-300`}>
        <nav>
          <ul>
            {menuItems.map((item, index) => (
              <li key={index} className="mb-2">
                <Link 
                  to={item.path}
                  className={`flex items-center p-2 rounded transition-colors duration-200 ${location.pathname === item.path ? 'bg-blue-600' : 'hover:bg-blue-600'}`}
                  onClick={() => {
                    setMenuOpen(false);
                  }}
                >
                  {item.icon}
                  <span className="ml-2">{item.text}</span>
                </Link>
              </li>
            ))}
            <li className="mb-2">
              <button 
                onClick={handleLogout}
                className="flex items-center p-2 rounded transition-colors duration-200 hover:bg-blue-600 w-full text-left"
              >
                <LogOut size={20} />
                <span className="ml-2">Çıkış Yap</span>
              </button>
            </li>
          </ul>
        </nav>
      </aside>

      {/* Ana İçerik */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Üst Çubuk */}
        <header className="bg-white shadow-md">
          <div className="container mx-auto px-4">
            <div className="flex items-center justify-between py-4">
              <div className="flex items-center">
                <button onClick={toggleMenu} className="text-blue-500 focus:outline-none focus:text-blue-700 md:hidden">
                  <Menu size={24} />
                </button>
                <h1 className="text-xl font-bold text-blue-800 ml-2">Sigorta CRM</h1>
              </div>
            </div>
          </div>
        </header>

        {/* İçerik Alanı */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
          <div className="container mx-auto px-6 py-8">

              <Routes>
              <Route path="/" element={renderHomePage()} />
              <Route path="/profil" element={<Profile />} />
              <Route path="/hedefler" element={<h2>Hedefler Sayfası</h2>} />
              <Route path="/performans" element={<Performance />} />
              <Route path="/basarilar" element={<h2>Başarılar Sayfası</h2>} />
              <Route path="/talepler" element={<h2>Talepler Sayfası</h2>} />
              <Route path="/pdf" element={<PDFReader />} />
              <Route path="/satislar" element={<SalesList />} /> {/* Yeni ekleme */}
              

            </Routes>
          
          </div>
        </main>
      </div>
    </div>
  );
};

export default Layout;