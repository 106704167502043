import React, { useState, useEffect } from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import axios from 'axios';
import "tailwindcss/tailwind.css";
import { FaDollarSign, FaLightbulb } from 'react-icons/fa';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';



// pdf.js işçi dosyasını tanımla
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;


const DidYouKnow = () => {
  const facts = [
    "Türkiye'de trafik sigortası 1953 yılında zorunlu hale getirilmiştir.",
    "Kasko sigortası, aracınızı doğal afetlere karşı da korur.",
    "DASK, 1999 Marmara Depremi'nden sonra zorunlu hale getirilmiştir.",
    "Sağlık sigortası, koruyucu sağlık hizmetlerini de kapsar.",
    "Ferdi kaza sigortası, 7/24 dünya çapında geçerlidir.",
    "Konut sigortası, evinizin içindeki eşyaları da koruma altına alır.",
    "İMM sigortası, trafik sigortasının tamamlayıcısı niteliğindedir."
  ];

  const [fact, setFact] = useState('');

  useEffect(() => {
    setFact(facts[Math.floor(Math.random() * facts.length)]);
  }, []);

  return (
    <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-4 rounded-lg shadow-md">
      <div className="flex items-center">
        <FaLightbulb className="text-yellow-400 mr-2" size={24} />
        <p className="font-bold">Bunu biliyor muydunuz?</p>
      </div>
      <p className="mt-2">{fact}</p>
    </div>
  );
};

const LoadingSpinner = () => (
  <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
    <AiOutlineLoading3Quarters className="animate-spin text-white text-4xl mb-4" />
    <h2 className="text-center text-white text-xl font-semibold">Yapay zeka poliçe bilgilerini çıkarıyor...</h2>
    <p className="w-1/3 text-center text-white">Bu işlem birkaç saniye sürebilir, lütfen bekleyin.</p>
  </div>
);

// Anahtar kelime eşleştirme tablosu
const urunAnahtarKelimeleri = {
  "Trafik Sigortası": ["trafik", "zorunlu trafik", "ZORUNLU MALİ SORUMLULUK", "karayolları motorlu araçlar", "zorunlu mali sorumluluk"],
  "Kasko": ["kasko", "tam kasko", "genişletilmiş kasko"],
  "Konut Sigortası": ["konut"],
  "Sağlık Sigortası": ["sağlık", "tamamlayıcı sağlık", "TAMAMLAYICI SAĞLIK SİGORTA POLİÇESİ"],
  "Dask": ["Deprem", "Afet"],
  "İMM": ["İhtiyari Mali Sorumluluk", "İMM"],
  "Ferdi Kaza & Yol Yardım": ["yol yardım", "yol yardım plus", "PLUS", "ferdi kaza"],
};

// Yardımcı fonksiyon: Metni normalize et
function normalizeText(text) {
  return text.toLowerCase()
    .replace(/\s+/g, ' ')
    .trim()
    .normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

// Ürün adını standartlaştırma fonksiyonu
function standartlastirUrunAdi(urunAdi) {
  const normalizedUrunAdi = normalizeText(urunAdi);
  for (const [standartAd, anahtarKelimeler] of Object.entries(urunAnahtarKelimeleri)) {
    if (anahtarKelimeler.some(kelime => normalizedUrunAdi.includes(normalizeText(kelime)))) {
      return standartAd;
    }
  }
  return urunAdi;
}

const PDFReader = () => {
  const [extractedData, setExtractedData] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [newOrRenewal, setNewOrRenewal] = useState('');
  const [commission, setCommission] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isExtracting, setIsExtracting] = useState(false);
  const [isSaved, setIsSaved] = useState(false);




    // Komisyon oranları (Bu oranları bir config dosyasından veya API'den alabilirsiniz)
    const commissionRates = {
      'Trafik Sigortası': { new: 0.0030, renewal: 0.0030 },
      'Kasko': { new: 0.006, renewal: 0.006 },
      'Konut Sigortası': { new: 0.035, renewal: 0.035 },
      'Sağlık Sigortası': { new: 0.045, renewal: 0.045 },
      'Dask': { new: 0.006, renewal: 0.006 },
      'Ferdi Kaza & Yol Yardım': { new: 0.020, renewal: 0.020 },
      'İMM': { new: 0.010, renewal: 0.010 },  // XX yerine uygun oranları yazın
      
      // Diğer ürünler için oranlar...
    };
    
    const parseMoneyValue = (value) => {
      if (typeof value !== 'string') return 0;
      return parseFloat(value.replace(/[^\d,]/g, '').replace(',', '.')) || 0;
    };

    const calculateCommission = (productName, grossPremium, isNew) => {
      const rates = commissionRates[productName];
      if (!rates) return 0;
      const rate = isNew ? rates.new : rates.renewal;
      return grossPremium * rate;
    };

    const extractDataFromPDF = async (pdfFile) => {
      setLoading(true);
      setIsExtracting(true);
      try {
      const fileReader = new FileReader();
      fileReader.onload = async function () {
        const typedarray = new Uint8Array(this.result);
        const pdf = await pdfjsLib.getDocument(typedarray).promise;
  
        let text = "";
  
        for (let i = 1; i <= Math.min(3, pdf.numPages); i++) {
          const page = await pdf.getPage(i);
          const textContent = await page.getTextContent();
          textContent.items.forEach((item) => {
            text += item.str + " ";
          });
        }

        const apiKey = process.env.REACT_APP_OPENAI_API_KEY;

        if (!apiKey) {
          console.error("API anahtarı bulunamadı.");
          return;
        }

        let retries = 3;

        while (retries > 0) {
          try {
            const response = await axios.post(
              'https://api.openai.com/v1/chat/completions',
              {
                model: 'gpt-3.5-turbo',
                messages: [
                  {
                    role: 'user',
                    content: `Aşağıdaki PDF metninden şu bilgileri çıkar: Şirket Adı, Poliçe Numarası, Ürün Adı, Poliçe Net Primi, Poliçe Brüt Primi, Poliçe Başlangıç ve Bitiş Tarihi.
Net ve Brüt primler şu formatta olmalıdır: xx.xxx,xx TL veya x.xxx,xx TL veya xxx,xx TL (nokta binlik ayracı, virgül ondalık ayracı). Eğer bu format doğru çıkmıyorsa, düzelt ve tekrar gönder.

Dikkat: Şirket Adı çıkarılırken aşağıdaki ifadeler şirket adı olarak kabul edilmemelidir:
- Centalya Sigorta
- SİGORTAMEMTALYA SİGORTA
- Kobi Sigorta
- Sigortamolsun
- SİGORTAMOLSUN (eğer sigorta şirketinin ismi sigortamolsun olarak algılıyorsan yanlış demektir)

Ayrıca, Net Prim ve Brüt Prim şu şekilde ifade edilebilir:
- Net Prim: NET PRİM:, net prim:, Net Prim.
- Brüt Prim: PRİM, Poliçe Primi, Ödenecek Brüt Tutar, Ödenecek Toplam Tutar, Ödenecek Tutar, Brüt Tutar, Brüt Prim. 
- Net prim ve brüt prim genelde alt alta olurlar. Hata yapmanı istemiyorum. Ayrıca, dask poliçe numarası 8 rakamlı olur. Eğer daha fazla buluyorsan yanlış buluyorsun demektir.
Özellikle trafik, kasko, konut, imm gibi ürünlerde Net Prim ile Brüt Prim aynı değildir. Dask ve sağlık sigortasında ise net prim ve brüt prim aynıdır.
DASK poliçelerinde sadece "PRİM" ifadesinin yanındaki değer "Brüt Prim" olarak alınmalıdır. SİGORTA BEDELİ ve buna benzer büyük sayılar prim olarak alınmamalıdır. Net ve Brüt Prim, DASK poliçelerinde aynıdır ve genellikle alt alta bulunur. Lütfen "Brüt Prim" için yalnızca "PRİM" ifadesini ve Net Prim için de Net Prim ifadelerini kullan.
Lütfen bu bilgileri dikkatlice çıkar ve doğru kategorilere yerleştir. Dikkatli ol!

                   \n\n${text}`
                  },
                ],
                max_tokens: 300,
                temperature: 0.2,
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${apiKey}`,
                },
              }
            );
            const content = response.data.choices[0].message.content;
            let parsedData;
            try {
              const lines = content.split('\n');
              parsedData = {};
              lines.forEach(line => {
                const [key, value] = line.split(': ');
                if (key && value) {
                  parsedData[key.trim()] = value.trim();
                }
              });

              if (parsedData['Ürün Adı']) {
                parsedData['Ürün Adı'] = standartlastirUrunAdi(parsedData['Ürün Adı']);
              }

            } catch (parseError) {
              console.error("Veri işleme hatası:", parseError);
              parsedData = { error: "Veri işlenemedi", rawContent: content };
            }
            setExtractedData(parsedData);

            break;
          } catch (error) {
            console.error("PDF verisi işlenirken hata oluştu:", error);
            setMessage("PDF işlenirken bir hata oluştu.");
          } finally {
            setLoading(false);
            setIsExtracting(false);
          }
        }
      };
      fileReader.readAsArrayBuffer(pdfFile);
    } catch (error) {
      console.error("PDF verisi işlenirken hata oluştu:", error);
      setMessage("PDF işlenirken bir hata oluştu.");
    } finally {
      setLoading(false);
    }
  };

// Yasaklı şirket isimleri listesi
const yasakliSirketler = [
  "Centalya Sigorta",
  "SİGORTAMEMTALYA SİGORTA",
  "Kobi Sigorta",
  "Sigortamolsun",
  "SİGORTAMOLSUN"
];

// Şirket adını kontrol etmeden önce extractedData'nın null olup olmadığını kontrol et
if (extractedData && yasakliSirketler.includes(extractedData['SİGORTAMOLSUN'])) {
  extractedData['SİGORTAMOLSUN'] = "Bilinmeyen Şirket";  // Veya istediğiniz başka bir değeri atayabilirsiniz
}



  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      extractDataFromPDF(e.dataTransfer.files[0]);
    }
  };

  const handleChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      extractDataFromPDF(e.target.files[0]);
    }
  };

  const handleSave = async () => {
    if (isSubmitting) return;
    if (!extractedData || extractedData.error) {
      setMessage("Kaydedilecek geçerli veri bulunamadı.");
      return;
    }
    if (!newOrRenewal) {
      setMessage("Lütfen poliçenin yeni iş mi yoksa yenileme mi olduğunu seçin.");
      return;
    }
    
    setIsSubmitting(true);
    setMessage('Poliçe kaydediliyor...');
    
    try {
      const isNew = newOrRenewal === 'Yeni İş';
      const grossPremium = parseMoneyValue(extractedData['Poliçe Brüt Primi']);
      const calculatedCommission = calculateCommission(
        extractedData['Ürün Adı'],
        grossPremium,
        isNew
      );
      
      const dataToSend = { 
        ...extractedData, 
        "Yeni mi Yenileme mi": newOrRenewal,
        "commission": calculatedCommission
      };
      console.log("Gönderilen veri:", dataToSend);
      const response = await axios.post('https://zirvecrm.com/save_sale.php', dataToSend);
      console.log("Sunucu yanıtı:", response.data);
      if (response.data.success) {
        setCommission(calculatedCommission);
        setMessage('Poliçe başarıyla kaydedildi!');
        setIsSaved(true);
      } else {
        setMessage('Poliçe kaydedilirken bir hata oluştu: ' + (response.data.message || 'Bilinmeyen hata'));
      }
    } catch (error) {
      console.error('Poliçe kaydedilirken bir hata oluştu', error.response ? error.response.data : error);
      setMessage('Poliçe kaydedilirken bir hata oluştu: ' + (error.response ? error.response.data.message : error.message));
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleReset = () => {
    setExtractedData(null);
    setNewOrRenewal('');
    setCommission(null);
    setMessage('');
    setIsSaved(false);
  };

  const handleOptionSelect = (option) => {
    setNewOrRenewal(option);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      {isExtracting && <LoadingSpinner />}
      
      {!isSaved ? (
        <div
          className={`w-2/3 p-6 border-4 border-dashed rounded-lg bg-white shadow-md transition ${
            dragActive ? "border-blue-500" : "border-gray-300"
          }`}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <input
            type="file"
            accept="application/pdf"
            onChange={handleChange}
            className="hidden"
            id="input-file-upload"
          />
          <label
            htmlFor="input-file-upload"
            className="flex flex-col items-center justify-center w-full h-full cursor-pointer"
          >
            <div className="text-center">
              <p className="text-lg font-semibold text-gray-700">PDF Yüklemek için tıklayın veya sürükleyip bırakın</p>
              <p className="text-sm text-gray-500">Yalnızca PDF dosyalarını destekler</p>
            </div>
          </label>
        </div>
      ) : (
        <div className="w-2/3">
        <DidYouKnow />
        <button
          onClick={handleReset}
          className="w-full mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Yeni Poliçe Yükle
        </button>
      </div>
    )}
      
      {loading && <p className="mt-4 text-blue-600">PDF işleniyor...</p>}
      
      {extractedData && !isSaved && (
        <div className="w-2/3 mt-6 p-4 bg-white rounded-lg shadow-md">
          <h3 className="text-xl font-bold text-gray-800 mb-4">Çıkarılan Bilgiler:</h3>
          <pre className="text-gray-700 whitespace-pre-wrap">{JSON.stringify(extractedData, null, 2)}</pre>
          
          {!newOrRenewal ? (
            <div className="mt-4">
              <p className="mb-2 text-gray-700">Bu poliçe yeni iş mi yoksa yenileme mi?</p>
              <button
                onClick={() => handleOptionSelect('Yeni İş')}
                className="mr-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                disabled={isSubmitting}
              >
                Yeni İş
              </button>
              <button
                onClick={() => handleOptionSelect('Yenileme')}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                disabled={isSubmitting}
              >
                Yenileme
              </button>
            </div>
          ) : (
            <button
              onClick={handleSave}
              className={`mt-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Kaydediliyor...' : 'Poliçeyi Kaydet'}
            </button>
          )}
        </div>
      )}

{commission !== null && isSaved && (
        <div className="w-2/3 mt-4 p-4 bg-yellow-100 rounded-lg shadow-md flex items-center">
          <FaDollarSign className="text-green-500 mr-2" size={24} />
          <span className="text-lg font-bold text-green-700">
            Kazandığınız Prim: {commission.toFixed(2)} TL
          </span>
          <p className="ml-4 text-sm text-gray-600">
            Harika iş! Bu satışla hedeflerinize bir adım daha yaklaştınız.
          </p>
        </div>
      )}
      {message && <p className="mt-4 text-green-600">{message}</p>}
    </div>
  );
};

export default PDFReader;
